<template>
  <!-- <b-card-code title="Column Search Table"> -->
    <div class="card mb-5 mb-xl-10">
      <div class="card-body">
    <!-- input search -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Search"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
      ref="my-table"
      @on-selected-rows-change="selectionChanged"
      :columns="columns"
      :rows="datapasar"
      :rtl="direction"
      :line-numbers="true"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
      :select-options="{
        enabled: true,
      }"
    >
      <div slot="selected-row-actions">
        <b-button v-b-modal.modal-verif>Verifikasi</b-button>
      </div>
      <template
        slot="table-row"
        slot-scope="props"
      >

        <div
          v-if="props.column.field === 'tanggal_pelaporan'"
          class="text-nowrap"
        >
          <span class="text-nowrap">{{format_date(props.row.tanggal_pelaporan)}} </span>
        </div>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item  @click="$router.push('/pages/lihat-pemantauan-sembako/' + props.row.id)">
                <span>Lihat</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['10','25','50']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap "> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>

    <!-- modal verif -->
    <b-modal
      id="modal-verif"
      ref="modal"
      title="Verifikasi Data"
      centered
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOkverif"
    >
      <form ref="form" @submit.stop.prevent="handleSubmitverif">
        <b-form-group
          label="Status Verifikasi"
          label-for="name-input"
        >
          <b-form-select v-model="status_verifikasi" :options="option_verif"></b-form-select>
        </b-form-group>
      </form>
    </b-modal>


    </div>
    </div>
  <!-- </b-card-code> -->
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import Vue from 'vue';
import {
  BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BFormTextarea,BFormFile,BButton,BFormDatepicker
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import { codeColumnSearch } from './code'
import { quillEditor } from 'vue-quill-editor'
import Ripple from 'vue-ripple-directive'
import axios from 'axios';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Swal from 'sweetalert2'
import VueLoading from 'vuejs-loading-plugin'
import moment from 'moment'

// import { VueEditor } from "vue2-editor";

// overwrite defaults
Vue.use(VueLoading, {
  dark: true, // default false
  text: 'Loading', // default 'Loading'
  loading: true, // default false
  //customLoader: myVueComponent, // replaces the spinner and text with your own
  background: 'rgb(255,255,255)', // set custom background
  classes: ['myclass'] // array, object or string
})

export default {
  components: {
    BCardCode,
    VueGoodTable,
    quillEditor,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BFormTextarea,
    BFormFile,
    BButton,
    BFormDatepicker,
    Ripple,
    VueLoading,
    Vue
    // VueEditor
  },
  setup(){
    const editorOption = {
      modules: {
        toolbar: '#quill-toolbar',
      },
      placeholder: 'Jawaban',
    }
    return {
      editorOption
    }
  },
  data() {
    return {
      pageLength: 10,
      dir: false,
      codeColumnSearch,
      url: localStorage.getItem('baseapi'),
      pengguna: JSON.parse(localStorage.getItem('userData')),
      customToolbar: [
        ["bold", "italic", "underline"],
      ],
      columns: [
        {
          label: 'Nama Pasar',
          field: 'pasar',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Nama Pasar',
          },
        },
        {
          label: 'Provinsi',
          field: 'provinsi',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Provinsi',
          },
        },
        {
          label: 'Nama Pelapor',
          field: 'nama_user',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Nama Pelapot',
          },
        },
        {
          label: 'Status',
          field: 'status_verifikasi',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Status',
          },
        },
        {
          label: 'Tanggal Laporan',
          field: 'tanggal_pelaporan',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Tanggal Pelaporan',
          },
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      datapasar:[],
      searchTerm: '',
      id_verif:[],
      option_verif: [
        { value: 'Sudah Diverifikasi', text: 'Setuju' },
        { value: 'Butuh Perbaikan', text: 'Tolak' },
      ],
      status_verifikasi:''
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  mounted(){
    this.loaddata()
  },
  created() {
   
  },
  methods: {
    // getkabkot(){
    //   axios.get(this.url+'wilayah/kabupaten?id_prov='+this.pengguna.id_prov, {
    //   headers: {
    //       "Content-type": "application/json",
    //       // "xth": this.pengguna.token,
    //       }
    //   })
      
    //   .then(response => {
    //     var datakabupaten = response.data.data
    //     console.log(datakabupaten)
    //   })
    //   .catch(() => {
    //     toast({
    //       component: ToastificationContent,
    //       props: {
    //         title: 'Error fetching users list',
    //         icon: 'AlertTriangleIcon',
    //         variant: 'danger',
    //       },
    //     })
    //   })
    // },
    format_date(value){
			if (value) {
			  return moment(String(value)).format('DD-MM-YYYY')
			}
		},
    selectionChanged(){
     var tes= this.$refs['my-table'].selectedRows;
     console.log(tes)
     if (tes.length == 1) {
       this.id_verif = tes[0].id
     } else {
       var data = []
       for (let index = 0; index < tes.length; index++) {
         data.push(tes[index].id)
        //  var data = tes[index].id;
       }
      this.id_verif = data.join('||')
     }
     console.log(this.id_verif)
    },
    loaddata(){
      this.$loading(true);
      if(this.pengguna.hak_akses == 'admin' || this.pengguna.hak_akses == 'superuser') {
        var api = 'stok'
      } else {
        var api = 'stok?filter=id_kab,=,'+this.pengguna.id_kab+';status_verifikasi,!=,Sudah Diverifikasi'
      }
      axios.get(this.url+api, {
      headers: {
          "Content-type": "application/json",
          // "xth": this.pengguna.token,
          }
      })
      .then(response => {
        // const { users, total } = response.data.data
        this.datapasar = response.data.data
        this.$loading(false);
      })
      .catch( error => {
        this.$loading(false);
        if (error.response.data === 'Token Tidak Valid/Hak akses tidak sesuai') {
          this.$swal({
            title: error.response.data,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ok',
            allowOutsideClick: false
            }).then((result) => {
            if (result.isConfirmed) {
              localStorage.removeItem('userData')
              // Redirect to login page
              this.$router.push({ name: 'auth-login' })
            }
          })
        } else {
         this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        }
      })
    },
    handleOkverif(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmitverif()
    },
    resetModal() {
        this.status_verifikasi = ''
      },
    handleSubmitverif() {
      axios
            .put(
            this.url+`stok_verifikasi`,
            {
                // idptsp,
                id_verif:this.id_verif,
                status_verifikasi:this.status_verifikasi,
            }
            ,{
                headers:{
                // "xth": this.token
                }
            })
            .then(response => {
            
              this.loaddata()
              this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Success`,
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: `Berhasil`,
                    },
                  })
              this.$nextTick(() => {
                this.$bvModal.hide('modal-verif')
              })
              return response
              
            })
            .catch(error=>{    
              this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: error.response.data.message,
                      icon: 'CoffeeIcon',
                      variant: 'danger',
                    },
                  })       
              return error
          })

      // Hide the modal manually
      this.$nextTick(() => {
          this.$bvModal.hide('modal-edit')
      })
    },
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/quill.scss';
</style>
<style lang="scss" scoped>
form ::v-deep {
  // Quill Editor Style
  .quill-editor {
    .ql-container.ql-snow {
      border-bottom: 0 !important;
    }
  }
}
</style>